import React from 'react';
import { Helmet } from 'react-helmet';
import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@openedx/paragon';
import classNames from 'classnames';

// import messages from './messages';

const LargeLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      <div className="w-50 d-flex">
        <div className="col-md-12 bg-primary-1000">
          {/* <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL}>
            <Image className="logo position-absolute" alt={getConfig().SITE_NAME} src={getConfig().LOGO_WHITE_URL} />
          </Hyperlink> */}
          <div className="min-vh-100 d-flex align-items-center background">
            <div className={classNames({ 'large-yellow-line mr-n4.5': getConfig().SITE_NAME === 'edX' })} />
            <h1
              className={classNames(
                'display-2 mw-xs learn',
                { 'ml-6': getConfig().SITE_NAME !== 'edX' },
              )}
            >
              {/* {formatMessage(messages['start.learning'])} */}
              {/* <div className="text-accent-a">
                {formatMessage(messages['with.site.name'], { siteName: getConfig().SITE_NAME })}
              </div> */}
            </h1>
          </div>
          {/* <Image src="https://s3-alpha-sig.figma.com/img/1a0c/a07e/4981814a83e2e94ee56120440a7e490f?Expires=1714348800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=BOuFvgvNvdTvXqOhTT9ZQdhWBB2fHBzUZKMEdf6ULshtXiEwqQwX46cDyitHIvHjlN5uAZzz~1BdbVoEVVN7sRMdw~~ZCjgo2HTPMRifcICUBNhvcWlZthn-2wlOWqAIfT~Z6is2FDp64bMSn-iynpqvHAMwNcd9AC0OH3vq5ffp-rCB59X07pf6pkEe0pzxnbKtiCi7iBoyiygmY~3vspsjrSrYOhO6ql-pzQtgZPnFWRr2op73sf9SNnoVo1lX4Q~pd4SH0j1XG0qJkk-2r5d1GgY7Ih7DmEEE1cai4LgfkT4ikTxqPNu-1qSKg~r2ZI8BxluArbvwq~Z-vh7sKA__" className="ci-1" alt="course-image-1" /> */}
          {/* <Image src="https://s3-alpha-sig.figma.com/img/740e/307a/4acd3f97e1a35c06ebc970a1e9f07dc2?Expires=1714348800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZEm5U1HlxeYqqpj8eJwCmnImTDennP7IzbxGCquHQzIcavtftPv1ez7aBPf7lhSiiewnjAjJooHsCxRi7-Q6gCgrv4SCru7ggCgHRTuPVI35brwn-dAywxoRKD5692KTPuh5B3YQZG29HOl8DmpRb9YAplfbHixeAP9QvQ9JpkL~YK4Gog1g4HzHM1HZqo1MWIsiKH3-tWj525YPu2u2PCT3mInvev9RS9S6SypkNVdiQMaRED35M1parBVO3t3d1Ib5JxbC6MqH7CIDyYJAc0Y7e8wHfRiiWcSFs2-OY9N~1wFhARNRYoTi6~nuqHrvcaIiyrHlOf99PzGMF-MaSg__" className="ci-2" alt="course-image-2" /> */}
        </div>
        {/* <Image src="https://s3-alpha-sig.figma.com/img/fd5f/1385/ccb3bb78662a35b71a1d45420fe9cfcc?Expires=1714953600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=mbNBBDDgaqDuaRKsArJcVuEWcAWkMrWFIlpz7u58mHHMzy9faxMrGQ77vTp9H5QVqc7xVOp~PAvyxHVNMEC8I3AKr~nGwD2pPBbuhpGrI05V47-HKgSQHUskLWJ5hyLwa0R~sOVQvtcvGJV5btmynHZCFgD66dEXVhly4QCcSfr3WHN5gpfdRThaWTKDAZg5KcZTEd8ywtdVLV4k6qIX5RtEWlvsFF0jZ-Xx2vkgaEhdp5puAitPvMghzEOWLYEoV6dQi7aJ21Y2tduLUMZrXuG9XHvPS1tNPy9rzqJVf3WG7CpHHrY7Q~9YhVjast6BRJ9kSR8382ZMBWtRU5WlPg__" className="header-logo" alt="logo" /> */}
        {/* <div className='header_logo'></div> */}
        {/* <div className="col-md-3 p-0 bg-primary-1000">
          <svg className="ml-n1 w-100 h-100 large-screen-svg-primary" preserveAspectRatio="xMaxYMin meet">
            <g transform="skewX(171.6)">
              <rect x="0" y="0" height="100%" width="100%" />
            </g>
          </svg>
        </div> */}
      </div>
    </>
  );
};

export default LargeLayout;